import styled from "@emotion/styled"
import Color from "color"
import themeColors from "./theme-colors"

const MainView = styled.main`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  padding-top: 10rem;
  max-width: 1024px;
  display: flex;
  margin: 0 auto;

  @media (max-width: 1024px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  section {
    width: 100%;
    z-index: 1;
  }

  h1 {
    color: ${Color(themeColors.fontColor.value)
      .darken(0.1)
      .string()};
    font-weight: 600;
    font-size: 2rem;
    text-shadow: 1px 1px ${themeColors.fontColor.inverse};
  }
`

export default MainView
