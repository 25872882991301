import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { animated, config, useTransition } from "react-spring"
import styled from "@emotion/styled"
import { faPause } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay"
import { Swipeable } from "react-swipeable"
import BackgroundImage from "gatsby-background-image"
import MainView from "./main-view"

const Container = styled.section`
  height: 80vh;
  position: relative;
`

const ControlBar = styled.aside`
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  transition: all 0.2s ease-in-out;

  button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    display: inline;
    margin: 0;
    padding: 0;
  }

  :hover {
    transform: scale3d(1.2, 1.2, 1.2);
  }
`

const AnimationDiv = styled(animated.div)`
  display: inline;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const LayoutHero = styled.section`
  height: 100%;
  display: flex;

  div {
    flex: 1;
  }

  position: relative;
`

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min)) + min
}

const SlideShow = ({ style, children }) => {
  const [animationPaused, setAnimationPaused] = useState(false)

  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          sort: { fields: name, order: DESC }
          filter: { relativeDirectory: { eq: "slides" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 1280, background: "rgba(10,10,10,.2)") {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )

  const [index, setIndex] = useState(() =>
    getRandomInt(0, allFile.edges.length)
  )

  const transitions = useTransition(
    allFile.edges[index],
    ({ node }) => node.id,
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: config.molasses,
    }
  )

  useEffect(() => {
    if (animationPaused) {
      return
    }

    const handler = () => setIndex(state => (state + 1) % allFile.edges.length)

    const intervalId = setInterval(handler, 5000)

    handler()

    return () => clearInterval(intervalId)
  }, [animationPaused, allFile.edges.length])

  const toggleAnimation = e => {
    e.preventDefault()
    setAnimationPaused(!animationPaused)
  }

  const handlePrevious = () => {
    setAnimationPaused(true)
    setIndex(state => (state - 1) % allFile.edges.length)
  }

  const handleNext = () => {
    setAnimationPaused(true)
    setIndex(state => (state + 1) % allFile.edges.length)
  }

  const PlayControlBar = () => (
    <ControlBar>
      <button
        onClick={toggleAnimation}
        title={animationPaused ? "Resume animation" : "Pause animation"}
      >
        <FontAwesomeIcon
          icon={animationPaused ? faPlay : faPause}
          inverse={true}
        />
      </button>
    </ControlBar>
  )

  return (
    <>
      <Container style={style}>
        <Swipeable onSwipedLeft={handleNext} onSwipedRight={handlePrevious}>
          {transitions.map(({ item: { node }, props, key }) => (
            <AnimationDiv key={key} style={{ ...props }}>
              <LayoutHero title={node.name.replace(/-/g, " ")} style={style}>
                <BackgroundImage
                  loading="auto"
                  fluid={node.childImageSharp.fluid}
                />
              </LayoutHero>
            </AnimationDiv>
          ))}
        </Swipeable>

        <PlayControlBar />

        <MainView>
          <section>{children}</section>
        </MainView>
      </Container>
    </>
  )
}

export default SlideShow
